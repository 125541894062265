@import '../../../../scss/theme-bootstrap';

$hex-width: 40px;
$hex-per-row: 3;
$margin-width: 25px;

.product-shade-picker {
  clear: both;
  @include pie-clearfix;
  padding: 0 20px;
  @include breakpoint($medium-up) {
    padding: 0;
  }

  &__smoosh {
    display: inline-block;
    margin: 0 auto 20px;

    &-img {
      display: block;
    }
    @include breakpoint($medium-up) {
      @include swap-direction(margin, 0 0 0 20px);
      float: #{$rdirection};
    }
  }

  .product-shade-picker__header {
    @include font-inter-medium;
    margin: 0 0 0.2em;
  }

  .product-shade-picker__shadename {
    @include font-inter-regular;
  }

  .product-shade-picker__shadename {
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }

  .product-shade-picker__shades {
    @include pie-clearfix;
    white-space: nowrap;
    width: 100%;
    @include breakpoint($medium-up) {
      // Max width of the number of hexes we want per row
      // times the hex width, plus margin offset per hex
      max-width: ($hex-width * $hex-per-row + ($margin-width * $hex-per-row));
      float: #{$ldirection};
      width: auto;
    }
  }
  .product-shade-picker__shade {
    @include box-shadow(0 0 0 1px $color-gray);
    @include transition(box-shadow 0.3s);
    float: #{$ldirection};
    width: 30px;
    height: 30px;
    margin-bottom: 15px;
    margin-#{$rdirection}: 15px;
    @include breakpoint($medium-up) {
      width: $hex-width;
      height: $hex-width;
      margin-bottom: $margin-width;
      margin-#{$rdirection}: $margin-width;
    }
    &:hover,
    &.active {
      @include box-shadow(0 0 0 1px $color-black);
    }
  }
}
